<template>
    <form-container
        :id="idPrefix"
        :data-testid="idPrefix"
        ref="form"
        @on-submit="onSubmit"
        @onError="onError"
    >
        <div class="mb-2">
            <form-field-phone
                :data-testid="`${idPrefix}-phone-number-input`"
                name="phone"
                ref="phoneField"
                placeholder="Mobile Number"
                label="Mobile Number"
                v-model="phone"
            />
        </div>
        <form-button
            :label="ctaText"
            event-name="click_button_submit_agent_invite_form"
            :submitting="submitting"
        />
        <div class="mt-2 text-center text-md-start">
            <p class="small text-muted">
                By providing your number, you consent to receive text messages. Data rates may apply.
            </p>
        </div>
    </form-container>
</template>

<script>
    import FormFieldPhone from '@/components/base/FormFieldPhone'
    import FormButton from '@/components/base/FormButton'
    import FormContainer from '@/components/base/FormContainer'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import authMixin from '@/mixins/authMixin'
    import { Flows, getNextRoute } from '@/flow/flowController'
    import assert from 'assert'
    import { logger } from '@/utils/logger'
    import { getRealEstateAgentLead } from '@/services/auth'

    export default {
        name: 'AgentInviteForm',
        components: {
            'form-field-phone': FormFieldPhone,
            'form-button': FormButton,
            'form-container': FormContainer,
        },
        mixins: [authMixin],
        props: {
            ctaText: {
                type: String,
                default: 'Get Your Invite Code',
            },
            idPrefix: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                submitting: false,
                phone: '',
                options: {
                    phone: true,
                    phoneRegionCode: 'US',
                },
            }
        },
        methods: {
            onSubmit: async function () {
                if (this.submitting) {
                    return
                }
                this.submitting = true

                try {
                    // Check if form is valid and not already submitting
                    const isValid = await this.$refs.form.$refs.observer.validate()
                    if (!isValid) {
                        this.submitting = false
                        return
                    }

                    const data = {}
                    if (this.phone) {
                        data.phoneNumber = this.phone
                    }
                    this.overwriteSessionStorageWithCurrentData(data)
                    await this.setupAuthForRealtor()
                } catch (e) {
                    logger.error('Error on real estate landing page', e)
                    this.$logEvent('error_message', { message: 'unknown error: clearing storage and refreshing the page.' })
                    const startPath = appSessionStorage.getItem(sessionStorageKey.startPagePath)
                    appSessionStorage.clear()
                    return (window.location.href = startPath || '/agent')
                } finally {
                    this.submitting = false
                }
            },
            async setupAuthForRealtor() {
                const authResponse = await getRealEstateAgentLead(this.phone)
                if (authResponse.data.error) {
                    await this.handleAuthErrors(authResponse)
                    return true
                }
                if (authResponse.data.payload?.jwtTokens) {
                    appSessionStorage.setItem(sessionStorageKey.jwtTokens, JSON.stringify(authResponse.data.payload.jwtTokens))
                    await this.$router.push(getNextRoute(this.$router))
                } else {
                    logger.error('Did not receive JWT tokens', authResponse)
                }
            },
            onError() {
                if (this.errorMessage) {
                    this.$logEvent('error_message', { message: this.errorMessage })
                    switch (this.errorField) {
                        case 'phone':
                            this.applyErrorOnPhoneField(this.errorMessage)
                            break
                        default:
                            this.applyGeneralError(this.errorMessage)
                    }
                }
                this.submitting = false
            },
            applyGeneralError(errorMessage) {
                this.$refs.form.applyGeneralError(errorMessage)
            },
            applyErrorOnPhoneField(errorMessage) {
                this.$refs.form.applyErrorOnField({ phone: errorMessage })
            },
            overwriteSessionStorageWithCurrentData(data) {
                logger.info(`overwriteSessionStorageWithCurrentData: ${JSON.stringify(data)}`)
                appSessionStorage.removeItem(sessionStorageKey.phoneNumber)
                appSessionStorage.removeItem(sessionStorageKey.pifInviteCode)
                for (const [key, value] of Object.entries(data)) {
                    switch (key) {
                        case 'phoneNumber':
                            logger.info(`overwriteSessionStorageWithCurrentData setting phoneNumber to ${value}`)
                            appSessionStorage.setItem(sessionStorageKey.phoneNumber, value)
                            break
                        default:
                            assert(false, 'unexpected key in InviteForm success event payload')
                    }
                }
                logger.info(`overwriteSessionStorageWithCurrentData setting currentFlow to ${Flows.realEstateAgent}`)
                appSessionStorage.setItem(sessionStorageKey.currentFlow, Flows.realEstateAgent)
            },
        },
    }
</script>
