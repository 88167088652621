<template>
    <marketing-layout :has-landing-footer="true">
        <div class="container-fluid px-0">
            <section-block class="pb-sm-1 pt-4 pt-md-8">
                <div
                    id="origination-form-container"
                    class="container"
                >
                    <div class="row">
                        <div class="col-12">
                            <span class="pill mb-3">
                                <span class="section-header fw-bold">Real estate agents</span>
                            </span>
                            <h1 class="text-responsive mb-3">
                                Grow your business. <br class="md lg xl">
                                Put money in your <br class="md lg xl">
                                clients pocket.
                            </h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-7">
                            <h2 class="heading-5 text-responsive fw-light mb-3">
                                Help new and existing clients get exclusive access to the first home equity backed credit card. Clients can apply in as fast as 15 minutes. Up to $250K lines. $0 to get
                                a card.<sup><a href="#footnote_1">1</a></sup>
                            </h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-5">
                            <agent-invite-form
                                id-prefix="main-origination-form"
                                ref="agentInviteFormHero"
                            />
                        </div>
                    </div>
                </div>
            </section-block>
            <div class="mt-lg-7">
                <div
                    class="position-relative"
                    style="z-index: 1000"
                    @click="$refs.videoPlayer.toggleVisibility()"
                    @keydown="$refs.videoPlayer.toggleVisibility()"
                >
                    <video-player-play-button />
                    <img
                        src="@/assets/images/pages/marketing/landingV2_5/video-still.jpg"
                        class="w-100 img-fluid"
                        alt="Aven Card"
                    >
                </div>
                <video-player
                    name="sandwich_349"
                    src="https://www.youtube.com/embed/jiK3m6lm20M?autoplay=1&mute=0&color=white&fs=0&modestbranding=1&rel=0&showinfo=0&enablejsapi=1&origin=https%3A%2F%aven.com"
                    ref="videoPlayer"
                />
            </div>
            <section-block class="py-5">
                <div class="container mt-5 mt-md-7 text-center">
                    <section-header variant="text-primary fw-bold">
                        As featured on
                    </section-header>
                    <div class="row justify-content-md-center mt-2">
                        <div class="col col-md-4 col-lg-3 mt-3">
                            <img
                                src="@/assets/images/global/bankrate_logo.jpg"
                                alt="BankRate"
                                class="partner-logo"
                                width="162"
                            >
                        </div>
                        <div class="col col-md-4 col-lg-3 mt-3">
                            <img
                                src="@/assets/images/global/trustpilot_logo.jpg"
                                alt="Trust Pilot"
                                class="partner-logo"
                                width="197"
                            >
                        </div>
                        <div class="col col-md-4 col-lg-3 mt-3">
                            <img
                                src="@/assets/images/global/lendingtree_logo.jpg"
                                alt="Lending Tree"
                                class="partner-logo"
                                width="171"
                            >
                        </div>
                    </div>
                </div>
            </section-block>
            <section-block>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="text-responsive text-md-center">
                                How your business <br class="md lg xl">
                                will grow.
                            </h1>
                        </div>
                    </div>
                    <div class="row mt-1 mt-md-7 gy-5">
                        <div class="col-12 col-md-4">
                            <img
                                src="@/assets/images/pages/marketing/realestate/GiftIcon.svg"
                                width="32"
                                height="32"
                                class="mb-2"
                                alt="Gift $250 To Clients, On Us"
                            >
                            <p>
                                <strong>Gift $250 To Clients, On Us</strong><br>
                                When a client gets an Aven card using your exclusive invite code, Aven will welcome them with $250 in statement credit. A gift from you, paid by us.
                            </p>
                        </div>
                        <div class="col-12 col-md-4">
                            <img
                                src="@/assets/images/pages/marketing/realestate/DreamHomeIcon.svg"
                                width="32"
                                height="32"
                                class="mb-2"
                                alt="Help Clients Get More Home"
                            >
                            <p>
                                <strong>Help Clients Get More Home. <br>
                                    Using Their Home.</strong><br>
                                Make it possible to do that kitchen makeover or buy a vacation home by quickly tapping into their home equity. As fast as 15 minutes, all online. And with no upfront
                                costs.
                            </p>
                        </div>
                        <div class="col-12 col-md-4">
                            <img
                                src="@/assets/images/pages/marketing/realestate/PriceTagIcon.svg"
                                width="32"
                                height="32"
                                class="mb-2"
                                alt="Get The Most Out Of A Listing"
                            >
                            <p>
                                <strong>Get The Most Out Of A Listing</strong><br>
                                Help finance your clients’ home makeover before they sell, so everyone can get the most out of selling a home. A win-win.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-5 d-md-none">
                            <img
                                src="@/assets/images/pages/marketing/landing/aven-credit-card-reader@2x.jpg"
                                class="img-fluid"
                                alt="Aven Card"
                            >
                        </div>
                    </div>
                </div>
            </section-block>

            <what-is-it-section />

            <reviews-section theme="trustpilot" />

            <section-block>
                <div class="container">
                    <div class="row">
                        <div class="col12 col-md-6">
                            <h1 class="text-responsive mb-2 mb-md-3">
                                Clients close in minutes, not weeks.
                            </h1>
                            <h5 class="text-responsive text-responsive fw-light">
                                Unlike traditional home equity products that can take weeks, your clients can close in as fast as 15 minutes, all from their phone.
                            </h5>
                        </div>
                        <div class="col-12 col-md-6">
                            <img
                                src="@/assets/images/pages/marketing/landing/HowItWorksHero@2x.jpg"
                                class="img-fluid"
                                alt="100% Online"
                            >
                        </div>
                    </div>
                    <div class="row mt-5 mt-md-9 align-items-center">
                        <div class="col-12 col-md-6 order-md-1">
                            <div>
                                <div class="d-flex">
                                    <h5 class="me-2 me-md-3">
                                        1.
                                    </h5>
                                    <div>
                                        <h5 class="mb-0 mb-md-1">
                                            Check Offer
                                        </h5>
                                        <p>Answer a few simple questions to quickly confirm your identity and home. <strong>Does not affect your credit score.</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="d-flex">
                                    <h5 class="me-2 me-md-3">
                                        2.
                                    </h5>
                                    <div>
                                        <h5 class="mb-0 mb-md-1">
                                            Confirm Income
                                        </h5>
                                        <p>A flexible yet simple process to instantly verify your bank account or pay stubs. You pick which works for you.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="d-flex">
                                    <h5 class="me-2 me-md-3">
                                        3.
                                    </h5>
                                    <div>
                                        <h5 class="mb-0 mb-md-1">
                                            Sign Documents
                                        </h5>
                                        <p>Simply and easily connect with our notary to sign your documents entirely digitally.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 order-md-0 mt-3 mt-md-0">
                            <img
                                src="@/assets/images/pages/marketing/landing/HowitWorksSigning@2x.jpg"
                                class="img-fluid"
                                alt="100% Online"
                            >
                        </div>
                    </div>
                </div>
            </section-block>

            <section-block>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-12 col-md-7">
                            <h1 class="text-responsive">
                                Just the numbers...
                            </h1>
                            <h5 class="text-responsive fw-light mt-2 mt-md-3">
                                Your clients get the low rates and lines sizes like home equity products, plus unlimited cashback. All without any of the typical origination fees.
                            </h5>
                        </div>
                        <div class="col-12 col-md-5">
                            <div class="list">
                                <div>
                                    <p>
                                        Variable APR<sup><a href="#footnote_1">1</a> </sup>
                                    </p>
                                    <p class="fw-bold">
                                        3.49% – 10.99%
                                    </p>
                                </div>
                                <div>
                                    <p>Line Size</p>
                                    <p class="fw-bold">
                                        $5K – $250K
                                    </p>
                                </div>
                                <div>
                                    <p>Unlimited Cashback</p>
                                    <p class="fw-bold">
                                        1.5%
                                    </p>
                                </div>
                                <div>
                                    <p>Balance Transfer Fee</p>
                                    <p class="fw-bold">
                                        2%
                                    </p>
                                </div>
                                <div>
                                    <p>Cash Out Fee</p>
                                    <p class="fw-bold">
                                        2%
                                    </p>
                                </div>
                                <div>
                                    <p>Origination Fee</p>
                                    <p class="fw-bold">
                                        $0
                                    </p>
                                </div>
                                <div>
                                    <p>Annual Fee</p>
                                    <p class="fw-bold">
                                        $0
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section-block>

            <section-block>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="text-responsive text-md-center">
                                How clients can <br class="md lg xl">
                                use Aven
                            </h1>
                        </div>
                    </div>
                    <div class="row mt-1 mt-md-7 gy-5">
                        <div class="col-12 col-md-5">
                            <div class="position-relative">
                                <img
                                    class="img-fluid"
                                    src="@/assets/images/pages/marketing/landing/UnlimitedCashback@2x.jpg"
                                    alt="Limits"
                                >
                                <div
                                    id="cashbackCarousel"
                                    class="cashback-carousel slide"
                                    data-ride="carousel"
                                >
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <img
                                                src="@/assets/images/pages/marketing/landing/CashBackCaption1.png"
                                                class="d-block"
                                                alt="Cashback"
                                                width="288"
                                                height="86"
                                            >
                                        </div>
                                        <div class="carousel-item">
                                            <img
                                                src="@/assets/images/pages/marketing/landing/CashBackCaption2.png"
                                                class="d-block"
                                                alt="Cashback"
                                                width="288"
                                                height="86"
                                            >
                                        </div>
                                        <div class="carousel-item">
                                            <img
                                                src="@/assets/images/pages/marketing/landing/CashBackCaption3.png"
                                                class="d-block"
                                                alt="Cashback"
                                                width="288"
                                                height="86"
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 offset-md-1">
                            <div class="row gy-4">
                                <div class="col-12">
                                    <img
                                        src="@/assets/images/pages/marketing/realestate/CreditCardIcon.svg"
                                        width="32"
                                        height="32"
                                        class="mb-2"
                                        alt="Gift $250 To Clients, On Us"
                                    >
                                    <p>
                                        <strong>Swipe Their Visa Cashback Card </strong><br>
                                        Clients get instant access to their funds via their Aven Card. So they can use what they want when they want – even for just a donut. Plus they earn unlimited
                                        1.5% cashback.
                                    </p>
                                </div>
                                <div class="col-12">
                                    <img
                                        src="@/assets/images/pages/marketing/realestate/CashIcon.svg"
                                        width="32"
                                        height="32"
                                        class="mb-2"
                                        alt="Help Clients Get More Home"
                                    >
                                    <p>
                                        <strong>Cash Out Directly To Their Bank</strong><br>
                                        Need cash in their bank account? Transfer the funds directly to their bank account for a single low rate 2% fee.
                                    </p>
                                </div>
                                <div class="col-12">
                                    <img
                                        src="@/assets/images/pages/marketing/realestate/BalanceTransferIcon.svg"
                                        width="32"
                                        height="32"
                                        class="mb-2"
                                        alt="Get The Most Out Of A Listing"
                                    >
                                    <p>
                                        <strong>Balance Transfer High Interest Cards</strong><br>
                                        Transfer high interest credit card debt to Aven and stop paying so much in montly interest. Just a single low rate 2% fee.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section-block>

            <easy-home-equity-section class="bg-light" />

            <about-us-section />

            <section-block class="bg-light">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-8 offset-md-2">
                            <h1 class="text-responsive">
                                Answer client questions
                            </h1>
                        </div>
                    </div>
                    <div class="row mt-3 mt-md-5">
                        <div class="col-12 col-md-8 offset-md-2">
                            <div>
                                <faq
                                    :question="$t('components.faq.homeEquityLoan.question')"
                                    :answer="$t(`components.faq.homeEquityLoan.answerHtml`)"
                                />
                                <faq
                                    :question="$t('components.faq.homeImpact.question')"
                                    :answer="$t('components.faq.homeImpact.answerHtml')"
                                />
                                <faq
                                    :question="$t('components.faq.criteria.question')"
                                    :answer="$t('components.faq.criteria.answerHtml')"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section-block>

            <section-block>
                <div class="container">
                    <div class="row gy-3">
                        <div class="col-12 col-md-6">
                            <div class="bg-light rounded-3 px-3 py-5 px-md-5 py-md-7">
                                <img
                                    src="@/assets/images/pages/marketing/realestate/GiftIcon.svg"
                                    width="48"
                                    height="48"
                                    alt="Contact Us"
                                    class="mb-3"
                                >
                                <h3 class="mb-3">
                                    Get your invite code
                                </h3>
                                <agent-invite-form
                                    id-prefix="main-origination-form"
                                    ref="agentInviteFormFooter"
                                />
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="bg-light rounded-3 px-3 py-5 px-md-5 py-md-7 h-100">
                                <img
                                    src="@/assets/images/pages/marketing/landing/PhoneIcon.svg"
                                    width="48"
                                    height="48"
                                    alt="Contact Us"
                                    class="mb-3"
                                >
                                <h3 class="mb-3">
                                    Reach out with<br>
                                    any questions
                                </h3>
                                <h5
                                    class="mb-3 fw-light"
                                    v-html="$t('pages.marketing.landing.reengage.contactInfoHtml')"
                                />
                                <a
                                    :href="smsHref"
                                    class="btn btn-secondary w-100"
                                >
                                    {{ $t('pages.marketing.landing.reengage.messageUs') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section-block>
        </div>
    </marketing-layout>
</template>

<script>
    import Marketing from '../../layouts/Marketing'
    import SectionBlock from '@/components/SectionBlock'
    import VideoPlayer from '@/components/VideoPlayer'
    import VideoPlayerPlayButton from '@/components/VideoPlayerPlayButton'
    import WhatIsItSection from '@/components/landing/WhatIsItSection'
    import SectionHeader from '@/components/SectionHeader'
    import { Carousel } from 'bootstrap'
    import EasyHomeEquitySection from '@/components/landing/EasyHomeEquitySection'
    import AboutUsSection from '@/components/landing/AboutUsSection'
    import Faq from '@/components/FaqExperimental'
    import AgentInviteForm from '@/components/AgentInviteForm'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import { Flows } from '@/flow/flowController'
    import ReviewsSection from '@/components/landing/ReviewsSection'
    import { contact_info } from '@/utils/contact-info-dictionary'

    export default {
        name: 'RealEstateAgents',
        metaInfo: {
            title: 'Real Estate Agents',
            meta: [{ name: 'description', vmid: 'description', content: 'Grow your business. Put money in your clients pocket.' }],
        },
        created: function () {
            appSessionStorage.setItem(sessionStorageKey.currentFlow, Flows.realEstateAgent)
        },
        mounted: async function () {
            const cashBackCarouselEl = document.getElementById('cashbackCarousel')
            const cashBackcarousel = new Carousel(cashBackCarouselEl, {
                interval: 1500,
            })
            cashBackcarousel.cycle()

            const useCaseCarouselEl = document.getElementById('useCaseCarousel')
            const useCasecarousel = new Carousel(useCaseCarouselEl, {
                interval: 1500,
            })
            useCasecarousel.cycle()

            this.$logEvent('view_real_estate_landing_page')
        },
        computed: {
            smsHref() {
                return `sms:${contact_info.sms}`
            },
        },
        components: {
            ReviewsSection,
            AgentInviteForm,
            Faq,
            AboutUsSection,
            EasyHomeEquitySection,
            'section-header': SectionHeader,
            'what-is-it-section': WhatIsItSection,
            'marketing-layout': Marketing,
            'section-block': SectionBlock,
            'video-player': VideoPlayer,
            'video-player-play-button': VideoPlayerPlayButton,
        },
    }
</script>

<style lang="scss">
    @import '../../styles/pages/marketing/landing';
    @import '../../styles/components/feesSection';

    .cashback-carousel {
        position: absolute;
        bottom: 24px;

        .carousel-item {
            padding-left: 24px;
        }
    }
</style>
